/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@media screen and (max-width: 599px) {
  .textfield-label {
    font-size: 14px;
  }
  .authBtnLogo {
    height: 30px;
    width: 30px;
  }
  .signupForm {
    width: 90%;
  }
  .editProfileForm {
    width: 90%;
  }
  .loginForm {
    width: 90%;
  }
  .ImageMainBox {
    height: 100px;
    width: 100px;
  }
  .createSpaceForm {
    width: 90%;
  }
  .transition-container-social-page {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 600px) {
  .textfield-label {
    font-size: 14px;
  }
  .authBtnLogo {
    height: 45px;
    width: 45px;
  }
  .signupForm {
    width: 95%;
  }
  .editProfileForm {
    width: 80%;
  }
  .loginForm {
    width: 95%;
  }
  .ImageMainBox {
    height: 110px;
    width: 110px;
  }
  .createSpaceForm {
    width: 70%;
  }
}
@media screen and (min-width: 900px) {
  .textfield-label {
    font-size: 16px;
  }
  .authBtnLogo {
    height: 45px;
    width: 45px;
  }
  .signupForm {
    width: 80%;
  }
  .editProfileForm {
    width: 500px;
  }
  .loginForm {
    width: 80%;
  }
  .ImageMainBox {
    height: 125px;
    width: 125px;
  }
  .createSpaceForm {
    width: 65%;
  }
}
@media screen and (min-width: 1200px) {
  /* .editProfileForm{
    width: 500px;
    
  } */
}

.transition-container {
  transition: transform 0.3s ease-in-out;
}

.slide-in {
  transform: translateX(0%);
}

.slide-out {
  transform: translateX(100%);
}
@media (max-width: 600px) {
  .button-xs {
    display: none;
  }
}

@media (min-width: 601px) {
  .button-sm {
    display: none;
  }
}
@media (min-width: 768px) {
  .button-tab {
    display: flex;
  }
}
.custom-modal .ant-modal-content {
  background-color: white; /* Set your desired background color */
}

.custom-header {
  color: blue; /* Set the color to blue */
  font-weight: 900;
}
.boldColumnHeader {
  font-weight: bold;
  color: blue; /* Change the color as desired */
}

.header-cell {
  font-weight: bold;
  color: blue; /* Change the color as desired */
}
/* .slide-out {
    transform: translateX(100%);
  } */
/* .transition-container-social-page{
    transition: transform 0.3s ease-in-out;
  }
  .slide-in {
    transform: translateX(0%);
  }
  
  .slide-out {
    transform: translateX(100%);
  } */
.ant-modal .ant-modal-body {
  background: transparent; /* Adjust transparency level */
}

.ant-modal .ant-modal-content {
  background: transparent; /* Background color for the modal content */
  box-shadow: none;
}

.custom-modal-wrapper .ant-modal-mask {
  background-color: transparent !important; /* Make modal mask transparent */
}

.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c4c1c1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #838282;
}
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* ******************************** */

.scrollbar::-webkit-scrollbar {
  width: 8px;
  /* height: 12px; */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #c4c1c1;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #838282;
}
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* ******************************** */

.tanstackscrollbar {
  overflow-x: auto;
  overflow-y: hidden; /* Adjust this if you also need vertical scroll */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.tanstackscrollbar::-webkit-scrollbar {
  height: 10px; /* Change width to height for horizontal scrollbar */
}

.tanstackscrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.tanstackscrollbar::-webkit-scrollbar-thumb {
  background: #c4c1c1;
  border-radius: 10px;
}

.tanstackscrollbar::-webkit-scrollbar-thumb:hover {
  background: #838282;
}
.tanstackscrollbar::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select {
  background: blue; /* Set the background color */
  border: none; /* Remove the border */
  color: white; /* Text color inside the select */
  appearance: none; /* Remove default styling in some browsers */
  -webkit-appearance: none; /* Remove default styling in Safari */
  -moz-appearance: none; /* Remove default styling in Firefox */
  padding-right: 30px; /* Add space for the arrow */
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  outline: none; /* Remove outline on focus */
}

.custom-select:focus {
  outline: none; /* Ensure no border or outline on focus */
}

.custom-select::after {
  content: '▼'; /* Custom arrow */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the arrow from blocking clicks */
}
@keyframes foldUp {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  /* 50% {
    transform: scale(1) rotate(360deg);
    opacity: 0.7;
  } */
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

.ant-notification-notice {
  background-color: #cce0ff !important; /* Change background color */
  border-radius: 14px !important;
  border: 1px solid #1d7afc;
}
.ant-notification-notice-message {
  font-family: 'Libre Franklin', sans-serif;
}
.ant-notification-notice-wrapper {
  background-color: transparent;
  border-radius: 14px !important;
}

.ant-notification-notice-description {
  font-family: 'Libre Franklin', sans-serif;
}

.swiper-slide img {
  transition: opacity 2s ease, width 0.5s ease;
}

.swiper-slide-active img {
  opacity: 1;
  width: 100%;
}

.swiper-slide-prev img,
.swiper-slide-next img {
  opacity: 0.3;
  width: 85%;
}

.fbSwiper .swiper-slide {
  opacity: 1 !important;
  transition: none !important;
}
.fbSwiper .swiper-slide img {
  opacity: 1 !important;
  transition: none !important;
}

@-webkit-keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@-webkit-keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@-webkit-keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@-webkit-keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}
